<nz-spin [nzSpinning]="loading">
  <button nz-button (click)="addRow()" nzType="primary" [disabled]="isDisabled" style="margin-bottom: 10px">
    Add
  </button>

  <nz-table #editRowTable nzBordered [nzData]="listOfData" nzTableLayout="fixed" [nzFrontPagination]="false"
    [nzShowPagination]="false" class="custom-edit-tab">
    <thead>
      <tr>
        <th *ngFor="let column of columns" [nzColumnKey]="column.key" [nzWidth]="width">
          {{ column.name }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of editRowTable.data">
        <ng-container *ngIf="!editCache[data.id]?.edit; else editTemplate">
          <td>{{ data.name }}</td>
          <td>{{ data.type }}</td>
          <td>{{ data.value }}</td>
          <td>
            <a (click)="startEdit(data.id)">Edit</a>
            <a (click)="deleteRow(data.device_property_id)" style="margin-left: 10px"
              *ngIf="data.device_property_id !== 0">Delete</a>
          </td>
        </ng-container>
        <ng-template #editTemplate>
          <td>
            <div style="position:relative;">
              <input
              required
              type="text"
              nz-input
              [(ngModel)]="editCache[data.id].data.name"
              (keyup)="nameUp($event)"
              #name="ngModel"
              (focus)="showSuggestion()"
              (blur)="hideSuggestion()"
              (ngModelChange)="inputNameChange($event, data.id)"
            />
              <div class="name-suggestion-box" [hidden]="suggestBox">
                <ul id="item-list">
                  <li *ngFor="let item of options" class="name" (click)="itemClicked(item.option, data.id)">{{item.option}}</li>
                </ul>
              </div>
            </div>
          </td>
          <td>
            <nz-select required [nzDisabled]="!disableType" nzAllowClear
              (ngModelChange)="typeChange($event)" nzPlaceHolder="Select a type"
              [(ngModel)]="editCache[data.id].data.type" style="width: 150px">
              <nz-option nzValue="text" nzLabel="Text"> </nz-option>
              <nz-option nzValue="number" nzLabel="Number"> </nz-option>
              <nz-option nzValue="coordinates" nzLabel="Coordinates"> </nz-option>
              <nz-option nzValue="time" nzLabel="Time Zone"> </nz-option>
            </nz-select>
          </td>
          <td>
            <input *ngIf="selectedType === 'text'" type="text" nz-input
              (change)="onValueChange(data.id, 'text', $event)" [ngModel]="editCache[data.id].data.value" />
            <input *ngIf="selectedType === 'number'" type="number" nz-input
              (change)="onValueChange(data.id, 'number', $event)" [ngModel]="editCache[data.id].data.value" />
            <div *ngIf="selectedType === 'coordinates'">
              <input nz-input nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomLeft" [nzClickHide]="false"
                placeholder="Enter a value" type="button" [ngModel]="coordinates" style="text-align: left" />
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item>
                    <label for="longitude">Longitude</label>
                    <input type="text" nz-input placeholder="Longitude"
                      (change)="onValueChange(data.id, 'longitude', $event)" id="longitude" [ngModel]="longitude" />
                  </li>
                  <li nz-menu-item>
                    <label for="latitude">Latitude</label>
                    <input type="text" nz-input placeholder="Latitude"
                      (change)="onValueChange(data.id, 'latitude', $event)" id="latitude" [ngModel]="latitude" />
                  </li>
                </ul>
              </nz-dropdown-menu>
            </div>
            <div *ngIf="selectedType === 'time'">
              <nz-select [(ngModel)]="editCache[data.id].data.value">
                <nz-option *ngFor="let timezone of timezones" [nzLabel]="timezone" [nzValue]="timezone"></nz-option>
              </nz-select>
            </div>
          </td>
          <td>
            <a (click)="saveEdit(data.id)" class="save">Save</a>
            <a nz-popconfirm nzPopconfirmTitle="Sure to cancel?" (nzOnConfirm)="cancelEdit(data.id)">Cancel</a>
          </td>
        </ng-template>
      </tr>
    </tbody>
  </nz-table>
</nz-spin>
