import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgAntdModule } from './ng-antd.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableComponent } from '../components/table/table.component';
import { ToasterComponent } from '../components/toaster/toaster.component';
import { EditableTableComponent } from '../components/editable-table/editable-table.component';
import { SearchInputComponent } from '../components/search-input/search-input.component';
@NgModule({
  declarations: [
    TableComponent,
    ToasterComponent,
    EditableTableComponent,
    SearchInputComponent
  ],
  imports: [
    CommonModule,
    NgAntdModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    NgAntdModule,
    FormsModule,
    ReactiveFormsModule,
    TableComponent,
    ToasterComponent,
    EditableTableComponent,
    SearchInputComponent
  ]
})
export class SharedModule { }
