import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { AuthGuard } from "./auth.guard";

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/device/device.module').then((m) => m.DeviceModule),
          canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Dashboard',
          title: 'Dashboard',
        },

        pathMatch: 'full',
        // canActivate: [AuthGuard]
        
      },
      // {
      //   path: 'dashboard',
      //   component: HomeComponent,
      //   data: {
      //     breadcrumb: 'Dashboard',
      //     title: 'Dashboard',
      //   },

      //   pathMatch: 'full',
      //   canActivate: [AuthGuard]
        
      // },
      {
        path: 'users',
        loadChildren: () =>
          import('./pages/user/user.module').then((m) => m.UserModule),
          canActivate: [AuthGuard]
      },
      {
        path: 'customers',
        loadChildren: () =>
          import('./pages/customer/customer.module').then(
            (m) => m.CustomerModule
          ),
          canActivate: [AuthGuard]
      },
      {
        path: 'devices',
        loadChildren: () =>
          import('./pages/device/device.module').then((m) => m.DeviceModule),
          canActivate: [AuthGuard]
      },
      {
        path: 'history',
        loadChildren: () =>
          import('./pages/history/history.module').then(
            (m) => m.HistoryModule
          ),
          canActivate: [AuthGuard]
      },
      {
        path: '',
        loadChildren: () =>
          import('./pages/device-management/device-management.module').then((m) => m.DeviceManagementModule),
          canActivate: [AuthGuard]
      },
      
      {
        path: '**',
        loadChildren: () =>
          import('./pages/device/device.module').then((m) => m.DeviceModule),
          canActivate: [AuthGuard]
        
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
