export const checkTableData = (tableData: any[]) => {
    const tableHeight = document.getElementsByClassName('ant-table-fixed') as HTMLCollectionOf<HTMLElement>;
    tableHeight[0].style.height = '0%';
    if(tableData.length === 0){
      console.log(tableData.length, "Table Length")
      console.log(tableHeight[0])
      tableHeight[0].style.height = '100%';
    } else{
      tableHeight[0].style.height = '0%';
    }
  }