import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ProfileComponent } from 'src/app/pages/user/profile/profile.component';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
 
  @Input() pageTitle!: string;
  username: string | null | undefined= ''
  logout(){
    this.authService.logout();
  }
  getUserProfile(){
    let displayName = (localStorage.getItem('user'))
    this.username = displayName && JSON.parse(displayName).username.split('@')[0]
  }

  updateUserProfileModal(){
    const modalRef = this.nzModalService.create({
      nzTitle: 'Profile',
      nzContent: ProfileComponent,
      nzFooter: null,
      // nzComponentParams: {
      //   action: 'edit',
      //   id: id,
      // } as any,
    });
  }

 
  constructor(private readonly authService: AuthService, private readonly nzModalService: NzModalService) { }

  ngOnInit(): void {
    this.getUserProfile()
  }




}
