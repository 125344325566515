import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import {DeviceProperty} from '../types/device-property.model';
import {environment} from '../../environments/environment'
import { catchError, Observable, retry } from 'rxjs';
import {CustomError} from './error.service';



let headers = new HttpHeaders({
  'Content-Type': 'application/json'
  
});



interface devicePropertyApi{
device:{
name: string;
status: string;
manufacturer: string;
serial_no: string;
type: string;
model: string;
},
deviceProperty: DeviceProperty[]
}

// interface devicePropertyApi {
//   entryPoint: DeviceProperty[];
//   count: number;
// }

@Injectable({
  providedIn: 'root'
})
export class DevicePropertyService {


  apiUrl = environment.api_url;


  getDeviceProperties(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filters: Array<{ key: string; value: string[] }>
  ): Observable<devicePropertyApi> {  
    let params = new HttpParams()
      .append('page', `${pageIndex}`)
      .append('results', `${pageSize}`)
      .append('sortField', `${sortField}`)
      .append('sortOrder', `${sortOrder}`);
    filters.forEach(filter => {
      filter.value.forEach(value => {
        params = params.append(filter.key, value);
      });
    });
    
    return this.http
      .get<devicePropertyApi >(`${this.apiUrl}/api/DeviceProperty?limit=${pageSize}&offset=0`,  {headers: headers})
      
  }



  getPropertyByDevice(id: string): Observable<devicePropertyApi> {
    return this.http.get<devicePropertyApi>(`${this.apiUrl}/api/DeviceProperty/getByDevice/${id}`)
     
  }

  addDeviceProperty(device: any): Observable<devicePropertyApi> {
    return this.http.post<devicePropertyApi>(`${this.apiUrl}/api/DeviceProperty`, device)
     
  }

  updateDeviceProperty(id: number, device: any): Observable<devicePropertyApi> {
    return this.http.put<devicePropertyApi>(`${this.apiUrl}/api/DeviceProperty/update/${id}`, device)
    
  }

  deleteDeviceProperty(id: number): Observable<devicePropertyApi> {
    return this.http.put<devicePropertyApi>(`${this.apiUrl}/api/DeviceProperty/delete/${id}`, {})
    
  }



  constructor(private http: HttpClient, public showError: CustomError) { }
}
