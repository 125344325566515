<nz-sider
  nzCollapsible
  [(nzCollapsed)]="collapseVal"
  [nzTrigger]="null"
  nzWidth="250px"
>
  <div class="logo">
    <div *ngIf="collapseVal; then logov1; else logov2">here is ignored</div>
    <ng-template #logov1
      ><img src="../../../assets/Infratech/logo-v2.svg"
    /></ng-template>
    <ng-template #logov2
      ><img src="../../../assets/Infratech/logo-v1.svg" />
    </ng-template>
  </div>
  <span
    class="trigger menu-icon"
    nz-icon
    [nzType]="collapseVal ? 'right' : 'left'"
    (click)="isCollapseFunc(collapseVal)"
    [style]="collapseVal ? 'margin-left:80%' : 'margin-left: 91%;'"
  ></span>
  <ul nz-menu nzTheme="dark" nzMode="inline">
    <!-- <li
      nz-menu-item
      routerLinkActive="ant-menu-item-selected"
      routerLink="/dashboard"
    >
      <span nz-icon nzType="dashboard"></span>
      <span>Dashboard</span>
    </li> -->
    <li
      nz-menu-item
      routerLinkActive="ant-menu-item-selected"
      routerLink="/users"
    >
      <span nz-icon nzType="user"></span>
      <span>Users</span>
    </li>
    <li
      nz-menu-item
      routerLinkActive="ant-menu-item-selected"
      routerLink="/customers"
    >
      <span nz-icon nzType="usergroup-add"></span>
      <span>Customers</span>
    </li>
    <li
      nz-menu-item
      routerLinkActive="ant-menu-item-selected"
      routerLink="/devices"
    >
    <span nz-icon nzType="branches" nzTheme="outline"></span>
    
  
    <!-- <span class="material-icons" style="font-size:14px;color:#fff;">
      devices
      </span> -->
      <span style="margin-left:10px;">Devices</span>
    </li>
    <li nz-submenu nzIcon="laptop" nzTitle="Device Management">
      
      <ul>
        <li
          nz-menu-item
          routerLinkActive="ant-menu-item-selected"
          routerLink="/device-monitoring"
        >
          Device Monitoring
        </li>
        <li
          nz-menu-item
          routerLinkActive="ant-menu-item-selected"
          routerLink="/unit"
        >
          Unit Management
        </li>
        <li></li>
      </ul>
    </li>
    <li nz-submenu nzIcon="laptop" nzTitle="History">
      
      <ul>
        <li
          nz-menu-item
          routerLinkActive="ant-menu-item-selected"
          routerLink="/history"
        >
          Login History
        </li>
        <li></li>
      </ul>
    </li>
   
  </ul>
</nz-sider>
