<nz-layout>
  
    <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null" nzWidth="250px">
      <app-sidebar [collapseVal]="isCollapsed" (isCollapsed) = "collapseChange()"></app-sidebar>
    </nz-sider>
    
    <nz-layout>
      
      <app-header [pageTitle]="Pagetitle"></app-header>
      <nz-content>
        
        
        <div class="inner-content">
          <router-outlet></router-outlet>
          
        </div>
      </nz-content>
      <app-footer></app-footer>
    </nz-layout>
  </nz-layout>
  