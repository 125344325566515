    <nz-header class="page-header" >
      <div class="navbar">
        <nz-page-header-title>
          <app-breadcrumbs></app-breadcrumbs>
        </nz-page-header-title>
        <div class="nav-items">
          <!-- <span>
            <img src="../../../assets/notify-icon.png" width="30"/>
          </span> -->
          <span class="user-name" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" nzPlacement="bottomRight">
            <span nz-icon nzType="user" nzTheme="outline"></span>
            {{username}}
          </span>
          <span class="user-name" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" nzPlacement="bottomRight" (click)="logout()">
            Logout
        
            <span nz-icon nzType="poweroff" nzTheme="outline"></span>
          </span>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <!-- <ul nz-menu>s
              <li nz-menu-item (click)="updateUserProfileModal()">Profile</li>              
            </ul> -->
          </nz-dropdown-menu>
          
        </div>
        
        
      </div>

      
        
      </nz-header>
