
import { NgModule } from '@angular/core';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { IconsProviderModule } from '../../icons-provider.module';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzBadgeModule} from 'ng-zorro-antd/badge';
import { NzResizableModule } from 'ng-zorro-antd/resizable';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

@NgModule({
    declarations: [
    ],
    imports: [
        NzLayoutModule,
        NzPageHeaderModule,
        NzBreadCrumbModule,
        NzMenuModule,
        IconsProviderModule,
        NzFormModule,
        NzRadioModule,
        NzInputModule,
        NzButtonModule,
        NzSpaceModule,
        NzTableModule,
        NzModalModule,
        NzMessageModule,
        NzDropDownModule,
        NzSelectModule,
        NzTypographyModule,
        NzCheckboxModule,
        NzSpinModule,
        NzPopconfirmModule,
        NzDrawerModule,
        NzDescriptionsModule,
        NzBadgeModule,
        NzResizableModule,
        NzSwitchModule,
        NzPopoverModule
    ],
    exports: [
        NzLayoutModule,
        NzMenuModule,
        NzPageHeaderModule,
        NzBreadCrumbModule,
        IconsProviderModule,
        NzFormModule,
        NzRadioModule,
        NzInputModule,
        NzButtonModule,
        NzSpaceModule,
        NzTableModule,
        NzModalModule,
        NzMessageModule,
        NzDropDownModule,
        NzSelectModule,
        NzTypographyModule,
        NzCheckboxModule,
        NzSpinModule,
        NzPopconfirmModule,
        NzDrawerModule,
        NzDescriptionsModule,
        NzBadgeModule,
        NzResizableModule,
        NzSwitchModule,
        NzPopoverModule
    ],
})
export class NgAntdModule { }