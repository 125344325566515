import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {environment} from '../../environments/environment'
import { CustomError } from './error.service';
import { LoginHistory } from '../types/loginHistory.model';





 interface User {
  username: string;
  password: string;
}

interface VerifyTokenEmail {
  userEmail: string;
  valid: boolean;
  token: string;
}
interface ChangePassword {
  token: string;
  email: string;
  password: string;
}
interface LoginHistoryApi {
  entryPoint: LoginHistory[];
  count: number;
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  API_URL = environment.api_url

  headers = new HttpHeaders().set('Content-Type', 'application/json');
  currentUser = {};


  login(user: User) {
    return this.httpClient.post<any>(`${this.API_URL}/api/AdminLogin/authenticate`, user, {
      responseType: 'text' as any,
    })
    
  }
  
  getAccessToken() {
    return localStorage.getItem('access_token');
  }

  isLoggedIn(): boolean {
    let authToken = localStorage.getItem('access_token');
    return (authToken !== null) ? true : false;
  }
  refreshToken(refersh_token: string) {
    return this.httpClient.post<any>(
      `${this.API_URL}/api/AdminLogin/refresh/${refersh_token}`
      , {})  // Replace "{}" with any required data for refresh
      .pipe(
        map((response) => {
          // Extract the new access token from the response (assuming it's in the response body)
          const newAccessToken = response.access_token;  // Replace "accessToken" with actual key name
          if (!newAccessToken) {
            throw new Error('Refresh failed: No access token in response');
          }
          localStorage.setItem('access_token', newAccessToken);
          return newAccessToken;
        }),
        catchError((error) => {
          // Handle refresh errors (e.g., network errors, invalid refresh token)
          return throwError(new Error('Refresh failed: ' + error));
        })
      );
  }

  logout() {
    const currentUrl = window.location.origin;
    window.location.assign(`${currentUrl}/#/auth/login`);
      localStorage.removeItem('access_token');
    localStorage.removeItem('user');
    localStorage.removeItem('refresh_token');
    
  }

 
  forgotPassword(email: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.API_URL}/api/AdminLogin/EmailVerification/${email}`,
      {
        responseType: 'text' as any,
      }
    );
  }
  verifyTokenEmail(token: string, email: string): Observable<VerifyTokenEmail> {
    return this.httpClient.get<VerifyTokenEmail>(
      `${this.API_URL}/api/AdminLogin/PassResetToken/${token}/${email}`
    );
  }
  changePassword(userDetail: ChangePassword): Observable<string> {
    return this.httpClient.put<string>(
      `${this.API_URL}/api/AdminLogin/PassResetTokenUpdate`,
      userDetail,
      {
        responseType: 'text' as any,
      }
    );
  }

  getLoginHistory(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    offset: number,
    filters: Array<{ key: string; value: string[] }>,
    search: string
  ): Observable<LoginHistoryApi> {
    let params = new HttpParams()
      .append('limit', `${pageSize}`)
      .append('offset', `${offset}`)
      .append('sort', `${sortOrder}`)
      .append('attr', `${sortField}`)
      .append('search', `${search}`)
      .append('page', `${pageIndex}`);

    filters.forEach((filter) => {
      filter.value.forEach((value) => {
        params = params.append(filter.key, value);
      });
    });

    return this.httpClient.get<LoginHistoryApi>(`${this.API_URL}/api/Login/loginHistory`, {
      params,
    });
    // .pipe(catchError(this.showError.handleError));
  }



  constructor(private httpClient: HttpClient,public router: Router, private showError: CustomError) { }
}
