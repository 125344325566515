import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.css']
})
export class SearchInputComponent implements OnInit {

  
  @Output() Searchsubmit: EventEmitter<any> = new EventEmitter();

  searchFormSubmit(e: any): void{
    this.Searchsubmit.emit(e.target.value);
    return e.target.value;
  }

  constructor(private search: UntypedFormBuilder) { }

  ngOnInit(): void {
   
  }

}
