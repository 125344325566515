import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Component, Injectable, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import {User} from '../types/userType';
import {environment} from '../../environments/environment'
import { CustomError } from './error.service';


  
interface userApi{
  entryPoint: User[];
  count: number
}
  
  @Injectable({ providedIn: 'root' })
  export class RandomUserService {
    
    apiUrl = environment.api_url;
  
    
    
    getUsers(
      pageIndex: number,
      pageSize: number,
      sortField: string | null,
      sortOrder: string | null,
      offset: number,
      filters: Array<{ key: string; value: string[] }>,
      search: string
    ): Observable<userApi> {  
      let params = new HttpParams()
        .append('limit', `${pageSize}`)
        .append('offset', `${offset}`)
        .append('sort', `${sortOrder}`)
        .append('attr', `${sortField}`)
        .append('search', `${search}`)
        .append('page', `${pageIndex}`);
      filters.forEach(filter => {
        filter.value.forEach(value => {
          params = params.append(filter.key, value);
        });
      });
      
      return this.http
        .get<userApi >(`${this.apiUrl}/api/User`, {params})
        // .pipe(catchError(this.showError.handleError));
        
        
    }

    addUser(user: {username: string, password: string, customer_id: number, status: string}): Observable<User>{
     return this.http.post<User>(`${this.apiUrl}/api/User`,  user)
    //  .pipe(catchError(this.showError.handleError));
     
    }
    getSingleUser(id: string): Observable<User>{
     return this.http.get<User>(`${this.apiUrl}/api/User/${id}`)
    //  .pipe(catchError(this.showError.handleError));
    }
    updateUser(id:number, user: any): Observable<User>{
      return this.http.put<User>(`${this.apiUrl}/api/User/updateSuperAdmin/${id}`, user)
      // .pipe(catchError(this.showError.handleError));
     }
    deleteUser(id:number): Observable<User>{
      return this.http.delete<User>(`${this.apiUrl}/api/User/${id}`, {})
      // .pipe(catchError(this.showError.handleError));
     }

     activeUser(id:number): Observable<boolean>{
      return this.http.get<boolean>(`${this.apiUrl}/api/Login/resendConfirmation/${id}`, {})
      // .pipe(catchError(this.showError.handleError));
     }
     getProfile(){
      return this.http.get<any>(`${this.apiUrl}/api/User/getProfile/`)

     }

     updateProfile(username: string){
      return this.http.put(`${this.apiUrl}/api/User/UpdateUsername/${username}`,{})
     }
  
    constructor(private http: HttpClient, public showError: CustomError) {}
  }