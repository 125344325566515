import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { ToasterService } from 'src/app/services/toaster.service';
import { environment } from 'src/environments/environment';

const currentUrl = window.location.origin;

@Injectable({
  providedIn: 'root',
})
export class CustomError {
  apiUrl = environment.api_url;
  constructor(public router: Router) {}

  handleError(error: any) {
    console.log('error', error);
    let errorMessage = '';
    let errorStatus: number;
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorStatus = error.error.status;
      errorMessage = error.error;
    }
    //Model errors from EntityError
    else if (error.error && error.error.errors) {
      errorStatus = error.error.status;
      errorMessage = error.error.errors.name?.[0] ?? 'Something went wrong';
    } else if (error && error) {
      errorStatus = error.status;
      errorMessage = error.error;
    } else {
      // server-side error
      errorStatus = 501;
      errorMessage = 'Something went wrong';
    }
    console.log(errorStatus);
    console.log(error.error);
    if (errorStatus === 401 || errorStatus === 501) {
      localStorage.removeItem('access_token') == null;
      window.location.assign(`${currentUrl}/#/auth/login`);
      errorMessage="Invalid Token"
    }
    console.log(errorMessage);

    return throwError(() => {
      return {
        status: errorStatus,
        message: errorMessage??"Something went wrong",
      };
    });
  }
}
