<div class="custom-table-wrapper">
  <div class="custom-table">
    <nz-table
      nzShowSizeChanger
      #headerTable
      [nzData]="list"
      [nzFrontPagination]="false"
      [nzLoading]="loading"
      [nzTotal]="total"
      [nzPageSize]="pageSize"
      [nzPageIndex]="pageIndex"
      (nzQueryParams)="paramsChange($event)"
      [nzScroll]="{ x: '1150px' }"
      nzSize="middle"
      [style.height]="height"
      
      
    >
      <thead>
        <tr>
          <th
            *ngFor="let column of columns; let i = index"
            [nzColumnKey]="column.key"
            [nzSortFn]="column.compare"
            [nzShowSort]="column.sort"
            [nzRight]="i === columns.length - 1 ? '0px' : false"
            [nzWidth]="column.width ? column.width : '200px'"
            [nzLeft]="i === 0 ? '0px' : false"
            
          >
            {{ column.name }}
          </th>
        </tr>
      </thead>
      <tbody style="min-height: 700px; max-height: 700px">
        <ng-content> </ng-content>
      </tbody>
    </nz-table>
    
  </div>
</div>
