
    <div class="search-box">
        <nz-input-group [nzSuffix]="suffixIconSearch" nzSize="large">
            <input nz-input placeholder="Search" (keyup)="searchFormSubmit($event)"/>
        </nz-input-group>
      
      <ng-template #suffixIconSearch>
        <span nz-icon nzType="search"></span>
      </ng-template>
    <!-- <button nz-button nzType="primary" nzSize="large" type="submit">
      <span nz-icon nzType="search" nzTheme="outline"  ></span>
    </button> -->
    </div>
    
      
    

