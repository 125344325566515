import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  @Output()
  isCollapsed = new EventEmitter<boolean>();
  @Input()
  collapseVal!: boolean;
  width = 0;
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.width = 300;
  }

  isCollapseFunc(value: boolean) {
    this.isCollapsed.emit(value);
    console.log(this.collapseVal);
  }
}
