import { ToasterService } from 'src/app/services/toaster.service';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { catchError, switchMap } from 'rxjs/operators';
import { EMPTY, Observable, throwError } from 'rxjs';
import { CustomError } from './services/error.service';
import { environment } from 'src/environments/environment';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private showError: CustomError,
    private toasterService: ToasterService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if(req.url==`${environment.api_url}/api/AdminLogin/authenticate`){
      return next.handle(req)
    }
    const accessToken = this.authService.getAccessToken();
    const refreshToken = localStorage.getItem('refresh_token');
    const hashToken = localStorage.getItem('hash_token');
    if (accessToken) {
      req = req.clone({
        setHeaders: {
          Authorization: `bearer ${accessToken}`,
        },
      });
      console.log(accessToken);
    }
    return next.handle(req).pipe(
      catchError((err) => {
        console.log(err);
        if (err.status === 401 || err.status === 501) {
          return this.tryRefreshToken(refreshToken!, req, next);
        }
        const error = err.error.message || err.error.text || err.error;
        console.log('Not auth');
        return throwError(error);
      })
    );
  }

  private tryRefreshToken(
    refreshToken: string,
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!refreshToken) {
      // No refresh token available, handle error (e.g., logout)
      return throwError(new Error('Refresh failed: No refresh token'));
    }

    // Call backend rearesh API (consider using a separate http service for refresh)
    return this.authService.refreshToken(refreshToken).pipe(
      switchMap((newAccessToken: string) => {
        // Refresh successful, update access token and retry request
        localStorage.setItem('access_token', newAccessToken);
        req = req.clone({
          setHeaders: {
            Authorization: `bearer ${newAccessToken}`,
          },
        });
        return next.handle(req);
      }),
      catchError((refreshError) => {
        // Refresh failed, handle error (e.g., logout)
        this.toasterService.successToaster({
          type: 'error',
          message:"Session Expired",
        }); 
        this.authService.logout();
        console.log('Refresh failed: ' + refreshError);
        return EMPTY;
      })
    );
  }
}
