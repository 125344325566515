import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from '../../../types/breadcrumb.model';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent implements OnInit {

  breadcrumbs$!: Observable<Breadcrumb[]>;
  constructor(public breadcrumbService: BreadcrumbService) { 
    
  }

  ngOnInit(): void {
    this.breadcrumbs$ = this.breadcrumbService.breadcrumbs$; 
  }

}
