<nz-spin [nzSpinning]="loading">
    <form
      nz-form
      [formGroup]="validateForm"
      class="login-form"
      (ngSubmit)="submitForm()"
    >
      <nz-form-item>
        <div nz-col nzSpan="24">
          <nz-form-label nzRequired nzFor="username">Username</nz-form-label>
        </div>
        <div nz-col nzSpan="24">
          <nz-form-control [nzErrorTip]="emailErrorUser">
            <nz-input-group nzPrefixIcon="user">
              <input
                type="email"
                nz-input
                formControlName="username"
                placeholder="Username"
              />
            </nz-input-group>
            <ng-template #emailErrorUser let-control>
              <ng-container *ngIf="control.hasError('email')"
                >The input is not valid E-mail!</ng-container
              >
              <ng-container *ngIf="control.hasError('required')"
                >Please input your E-mail!</ng-container
              >
            </ng-template>
          </nz-form-control>
        </div>
      </nz-form-item>
      <nz-form-item>
        <div nz-col nzSpan="24">
          <nz-form-label nzRequired nzFor="customer_name">Customer Name</nz-form-label>
        </div>
        <div nz-col nzSpan="24">
          <nz-form-control >
            <nz-input-group nzPrefixIcon="user">
              <input
                type="text"
                nz-input
                formControlName="customer_name"
                placeholder="Customer Name"
                
                
              />
            </nz-input-group>
            
          </nz-form-control>
        </div>
      </nz-form-item>
      <nz-form-item>
        <div nz-col nzSpan="24">
          <nz-form-label nzRequired nzFor="role">Role</nz-form-label>
        </div>
        <div nz-col nzSpan="24">
          <nz-form-control >
            <nz-input-group nzPrefixIcon="user" >
              <input
                type="text"
                nz-input
                formControlName="role"
                placeholder="Role"
                
                
              />
            </nz-input-group>
            
          </nz-form-control>
        </div>
      </nz-form-item>
  
     
     
  
      <nz-form-item>
        <div nz-col nzSpan="24">
          <nz-form-label nzFor="status">Status</nz-form-label>
        </div>
        <div nz-col nzSpan="24">
          <nz-form-control nzErrorTip="Please select user status!">
            <nz-radio-group formControlName="status" nzButtonStyle="solid" nzDisabled="true">
              <label nz-radio-button nzValue="Active">Active</label>
              <label nz-radio-button nzValue="Pending">Pending</label>
            </nz-radio-group>
          </nz-form-control>
        </div>
      </nz-form-item>
  
      <div class="modal-footer">
        <button nz-button nzType="default" type="button" (click)="cancel()">
          Cancel
        </button>
  
        <button
          nz-button
          nzType="primary"
          type="submit"
          [nzLoading]="isConfirmLoading"
        >
          Update Profile
        </button>
      </div>
    </form>
  </nz-spin>
  