import { Component, Injectable, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { checkTableData } from 'src/app/utils/tableHeight';


interface RandomUser {
  gender: string;
  email: string;
  name: {
    title: string;
    first: string;
    last: string;
  };
}

interface user {
  _id: string
  username: string;
  email: string;
  is_admin: boolean

}

@Injectable({ providedIn: 'root' })
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})

export class TableComponent implements OnInit {




  list!: any[];
  @Input() set tableData(data: any[]){
    this.list = data;
    checkTableData(this.list)
  };
  @Input() total!: number;
  @Input() loading!: boolean;
  @Input() pageSize!: number;
  @Input() pageIndex!: number;
  @Input() filterGender!: Array<{ key: string; value: string }>;
  @Input() columns!: Array<{ name: string, key: string, compare: any, priority: any, width?: string, sort: boolean }>;
  @Input() height!: string;
  @Input() fixedHeight!: string;

  @Output() loadDataFromServer: EventEmitter<{
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filter: Array<{ key: string; value: string[] }>
  }> = new EventEmitter();
  keys!: string[];
  

  // loadData(pageIndex: number,
  //   pageSize: number,
  //   sortField: string | null,
  //   sortOrder: string | null,
  //   filter: Array<{ key: string; value: string[] }>) {
  //   console.log(pageIndex, "Check")
  //   this.loadDataFromServer.emit({ pageIndex, pageSize, sortField, sortOrder, filter });
    
  // }
  @Output() onParamsChange: EventEmitter<any> = new EventEmitter();
  @Output() onResizeTable: EventEmitter<any> = new EventEmitter();

  

  

  paramsChange(params: NzTableQueryParams): void {
    console.log('check params', params);
    this.onParamsChange.emit(params);
  }

  


  constructor() { }

  ngOnInit(): void {
    // if(this.tableData.length === 0){
    //   this.nzConfigService.set('empty', { nzDefaultEmptyContent: this.customTpl });
    // }
   
  
    
    
  }

  



}
